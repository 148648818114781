﻿.btn {
  border-radius: 0;
  padding: 0.8rem 5rem;
  cursor: pointer;

  &.btn-primary {
    color: $ally-yellow;
    border: 1px solid transparent;
    border-radius: 40px;
    position: relative;

    span {
      position: relative;
      color: $ally-yellow;
    }

    &::before {
      transition: all 0.25s;
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 40px;
      background: $ally-magenta;
      width: 100%;
    }

    &:hover {
      background: $ally-yellow;
      span {
        color: $ally-magenta;
      }

      &::before {
        width: 55px;
        background: $ally-magenta;
      }
    }

    &:hover {
      background-position: -50px bottom;
      color: $ally-yellow !important;
    }
    &.active {
      border: 2px solid $ally-yellow !important;
      background: $ally-yellow !important;
      color: $ally-magenta !important;
    }
  }
}
