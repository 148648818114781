header {
  position: relative;
  width: 100%;
  overflow: hidden;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $ally-yellow;
    opacity: 0.3;
    z-index: 1;
  }
}

/*sticky header position*/
.sticky {
  .logo {
    transition: 100ms;
    z-index: 4;

    &.nav_is_open {
      width: 150px;

      @media screen and (max-width: 767px) {
        width: 100px;
      }
    }
  }

  &.is-sticky {
    transition: 500ms;
    background: $ally-white;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
    width: 100%;
    padding: 1rem;

    .logo:not(.nav_is_open) {
      width: 100px;
    }
  }
}
