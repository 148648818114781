﻿/* main navigation */
.top-nav {
  position: fixed;
  z-index: 10;
  top: 1.7rem;
  font-size: 16px;

  a {
    margin-right: 8rem;
  }

  &.left {
    left: 4%;
  }
  &.right {
    right: 3%;
  }
}

footer {
  position: relative;

  .bottom-nav {
    position: absolute;
    bottom: 1.7rem;
    font-size: 16px;

    a {
      margin-right: 8rem;
    }

    &.left {
      left: 4%;
    }
    &.right {
      right: 3%;
    }
  }
}

#toggle {
  position: relative;
  float: right;
  height: 27px;
  width: 38px;
  cursor: pointer;
  transition: opacity 0.25s ease;

  &:hover {
    span {
      background-color: $ally-magenta !important;
    }
  }

  &.active {
    .top {
      transform: translateY(11px) translateX(0) rotate(45deg);
      background: $ally-black;
    }
    .middle {
      opacity: 0;
      background: $ally-black;
    }

    .bottom {
      transform: translateY(-11px) translateX(0) rotate(-45deg);
      background: $ally-black;
    }
  }

  span {
    background: $ally-black;
    border: none;
    height: 5px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.35s ease;
    cursor: pointer;

    &:nth-of-type(2) {
      top: 11px;
    }

    &:nth-of-type(3) {
      top: 22px;
    }
  }
}

#mainNav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
  background-color: $ally-yellow;

  &.open {
    opacity: 1;
    visibility: visible;
    height: 100%;
    z-index: 3;

    li {
      animation: fadeInTop 0.5s ease forwards;
      opacity: 0;
      animation-delay: 0.75s;

      &:nth-of-type(2) {
        animation-delay: 0.8s;
      }
      &:nth-of-type(3) {
        animation-delay: 0.85s;
      }
      &:nth-of-type(4) {
        animation-delay: 0.9s;
      }
      &:nth-of-type(5) {
        animation-delay: 0.95s;
      }
      &:nth-of-type(6) {
        animation-delay: 1s;
      }
    }

    &.closing {
      li {
        opacity: 1;
        animation: fadeOutTop 0.5s ease forwards;

        &:nth-of-type(6) {
          animation-delay: 0.5s;
        }
        &:nth-of-type(5) {
          animation-delay: 0.55s;
        }
        &:nth-of-type(4) {
          animation-delay: 0.6s;
        }
        &:nth-of-type(3) {
          animation-delay: 0.65s;
        }
        &:nth-of-type(2) {
          animation-delay: 0.7s;
        }
        &:nth-of-type(1) {
          animation-delay: 0.75s;
        }
      }
    }
  }

  nav {
    position: relative;
    top: 55%;
    transform: translateY(-50%);
    text-align: center;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;

    li {
      display: block;
      line-height: 4rem;
      position: relative;
      opacity: 0;

      @media screen and (max-width: 767px) {
        line-height: 3rem;
      }

      a {
        font-family: "Arial Black", sans-serif;
        font-size: 25px;
        display: block;
        position: relative;
        color: $ally-black;
        text-decoration: none;
        overflow: hidden;
        transition: 300ms;

        &:hover,
        &.active {
          color: $ally-magenta;
        }

        @media screen and (max-width: 767px) {
          font-size: 19px;
        }
      }

      &.mobile {
        margin-top: 2rem;
        a {
          font-family: Arial, sans-serif;
          font-size: 16px;
        }
      }
    }
  }
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    top: -20%;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
@keyframes fadeOutTop {
  0% {
    opacity: 1;
    top: 0;
  }
  100% {
    opacity: 0;
    top: -20%;
  }
}

/* section navigation */
.section_nav {
  a {
    position: fixed;
    bottom: 7rem;
    left: 50%;
    z-index: 1;
    display: inline-block;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: $ally-black;
    font: normal 400 20px/1 Arial, sans-serif;
    letter-spacing: 0.1em;
    text-decoration: none;
    transition: opacity 0.3s;
    &:hover {
      span {
        border-color: $ally-magenta;
      }
    }

    span {
      position: absolute;
      top: 0;
      left: 50%;
      width: 32px;
      height: 32px;
      margin-left: -12px;
      border-left: 5px solid $ally-black;
      border-bottom: 5px solid $ally-black;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-animation: animate_arrow_down 1.5s infinite;
      animation: animate_arrow_down 1.5s infinite;
      box-sizing: border-box;
    }
  }
}

@-webkit-keyframes animate_arrow_down {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes animate_arrow_down {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

@-webkit-keyframes animate_arrow_up {
  0% {
    -webkit-transform: rotate(135deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(135deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes animate_arrow_up {
  0% {
    transform: rotate(135deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(135deg) translate(-20px, 20px);
    opacity: 0;
  }
}

@media screen and (max-width: 767px) {
  .navbar {
    padding: 0;
  }
  nav .logo {
    width: 100px;
  }

  .top-nav.left {
    display: none;
  }
  .top-nav.right {
    right: 2rem;
    top: 1.5rem;
  }
}
