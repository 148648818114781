﻿.modal {
  padding-right: 0 !important;
  font-size: 16px;

  .modal-fullscreen {
    min-width: 100%;
    margin: 0;

    .logo {
      width: 80px;
    }

    .modal-content {
      min-height: 100vh;

      .modal-header {
        border: 0;
      }

      .close {
        position: absolute;
        top: 30px;
        right: 39px;
        font-size: 16px;
        color: black;

        small {
          margin-top: 0.2rem;
          margin-right: 0.5rem;

          @media screen and (max-width: 480px) {
            display: none;
          }
        }

        .close-toggle {
          position: relative;
          display: inline-block;
          height: 27px;
          width: 38px;
          cursor: pointer;

          .top {
            transform: translateY(11px) translateX(0) rotate(45deg);
            background: $ally-black;
          }
          .middle {
            opacity: 0;
            background: $ally-black;
          }

          .bottom {
            transform: translateY(-11px) translateX(0) rotate(-45deg);
            background: $ally-black;
          }

          span {
            background: $ally-black;
            border: none;
            height: 5px;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;

            &:nth-of-type(2) {
              top: 11px;
            }

            &:nth-of-type(3) {
              top: 22px;
            }
          }
        }

        &:hover {
          color: $ally-magenta;
          span {
            background-color: $ally-magenta !important;
          }
        }
      }
    }
  }
}
