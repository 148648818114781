﻿body {
  font-family: Arial, sans-serif;
  font-size: 25px;
  font-weight: 400;
}

h1 {
  font-size: 40px;
  line-height: 56px;
  z-index: 1;

  strong {
    font-family: "Arial Black", sans-serif;
    font-weight: bold;
    font-size: 40px;
  }
}
h2 {
  font-size: 25px;
  font-weight: bold;
  line-height: 35px;
  margin-bottom: 0;
}
h3 {
  font-size: 25px;
  font-family: "Arial Black", sans-serif;
  font-weight: bold;
  line-height: 35px;
  margin-bottom: 0;
}

.btn {
  font-size: 22px;
  font-family: "Arial Black", sans-serif;
  font-weight: bold;
  line-height: 30px;
}

@media screen and (max-width: 480px) {
  body {
    font-size: 19px;
  }
  h1,
  h1 strong {
    font-size: 26px;
    line-height: 36px;
  }
  h2 {
    font-size: 19px;
    line-height: 26px;
  }
}
